<template>
    <div>
        		<v-flex xs12 lg12 sm12 xl12 class="text-center">
			<img src="../../assets/wasl.jpg" style="width:10%">
		</v-flex>
        <h1 class="text-center"> {{ $store.getters.store.store_name }} </h1>
        <h4 class="text-center"> {{ $store.getters.language.data.stock.report_title }} </h4>
        <div>
            <p style="font-size: 20px;" class="mb-1"> {{ $store.getters.language.data.stock.stock_date }}: {{ new Date(stock.stock_date).toISOString().split('T')[0] }} </p>
            <p style="font-size: 20px;" class="mb-1"> {{ $store.getters.language.data.stock.stock_invoice_number }}: {{ stock.stock_invoice_number }} </p>
            <p style="font-size: 20px;" class="mb-1"> {{ $store.getters.language.data.stock.stock_move_type_name }}: {{ stock.stock_move_type_name }} </p>
        </div>
        <table style="border: 1px solid; border-collapse: collapse; text-align: center; width: 100%;" class="mt-3">
            <thead>
                <tr>
                    <th style="border: 1px solid;"> {{ $store.getters.language.data.stock.item_name }} </th>
                    <th style="border: 1px solid;"> {{ $store.getters.language.data.stock.stock_qty }} </th>
                    <th style="border: 1px solid;"> {{ $store.getters.language.data.stock.stock_price }} </th>
                    <th style="border: 1px solid;"> {{ $store.getters.language.data.stock.stock_note }} </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in rows" :key="item.stock_id">
                    <td style="border: 1px solid;"> {{ item.item_name }} </td>
                    <td style="border: 1px solid;"> {{ item.stock_qty }} </td>
                    <td style="border: 1px solid;"> {{ (item.stock_price).toLocaleString() }} {{ $store.getters.store.store_payment == 'usd' ? '$' : ''}} </td>
                    <td style="border: 1px solid;"> {{ item.stock_note }} </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import requests from '../../requests/stock.request'
export default {
    data() {
        return {
            stock: {
                stock_date: new Date().toISOString().split('T')[0]
            },
            rows:[],
        }
    },
    mounted() {
        requests.reportStock(`stock_invoice_number=${this.$route.params.invoice_number}&stock_move_type_id=${this.$route.params.stock_move_type_id}`).then(r => {
            if (r.status == 200) {
                this.rows = r.data
                if(r.data.length) {
                    this.stock.stock_id = r.data[0].stock_id
                    this.stock.stock_invoice_number = r.data[0].stock_invoice_number
                    this.stock.stock_move_type_name = r.data[0].stock_move_type_name
                    this.stock.stock_date = new Date(r.data[0].stock_date).toISOString().split('T')[0];
                }
                var darkTheme = this.$vuetify.theme.dark
                if(darkTheme) {
                    this.$vuetify.theme.dark = false;
                }
                setTimeout(() => {
                    window.print()
                    if(darkTheme) {
                        this.$vuetify.theme.dark = true;
                    }
                    window.close()
                }, 500);
            } 
        })
    }
}
</script>
